.module-details-max-height {
  height: 100%;
  max-height: 700px;
  overflow-y: auto;
  @include screen-(xl) {
    max-height: 750px;
  }
  @include screen-(xxl) {
    max-height: 770px;
  }
  @include screen-(3xl) {
    max-height: 810px;
  }
}
